
import React from 'react';
import { Typography, Paper, Grid, Stack, Box, Chip } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { GET_THE_OBJECT_RECAP } from '../../../../../_shared/graphql/queries/TheObjectQueries';
import ProgressService from '../../../../../_shared/services/feedbacks/ProgressService';
import { getFormatDate, getFormatDateTime } from '../../../../../_shared/tools/functions';
import { useSession } from '../../../../../_shared/context/SessionProvider';
import { Repeat, Timeline } from '@mui/icons-material';
import ObjectRecoveryTimeline from './ObjectRecoveryTimeline';

export default function TheObjectReport({theObject}) {
    const { user } = useSession();
    const [getTheObject, { 
        loading : loadingTheObject,
        data: theObjectData, 
        error: theObjectError, 
      }] = useLazyQuery(GET_THE_OBJECT_RECAP)
    React.useEffect(()=>{
        if(theObject?.id){
            getTheObject(({ variables: { id: theObject.id } }));
        }
    }, [theObject])
    
    
    if(loadingTheObject) return <ProgressService type="form" />
    return (
            <Stack sx={{ height : '100%', alignItems : 'center', justifyContent : 'center'}}>
                <Paper elevation={0} sx={{ width: '100%'}}>
                    
                    <Grid container spacing={2}>
                        <Grid item="true" xs={6}>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                    <Box>
                                        {user?.company?.logo && <img src={user?.company?.logo} alt={user?.company?.name} width={120} />}
                                    </Box>
                                    <Box sx={{ paddingLeft: 1}}>
                                        {user?.company?.name && user?.company?.name !== '' && <Typography><b>{user?.company?.name}</b></Typography>}
                                        {user?.company?.address && user?.company?.address !== '' && <Typography>{user?.company?.address}</Typography>}
                                        {user?.company?.fix && user?.company?.fix !== '' && <Typography>{user?.company?.fix}</Typography>}
                                        {user?.company?.mobile && user?.company?.mobile !== '' && <Typography>{user?.company?.mobile}</Typography>}
                                        {user?.company?.email && user?.company?.email !== '' && <Typography>{user?.company?.email}</Typography>}
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item="true" xs={6}>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography sx={{ fontWeight: 700 }}>Objet n° <b>{theObjectData?.theObject?.number}</b></Typography>
                            </Paper>
                            {theObjectData?.theObject?.client ? <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography sx={{fontWeight: 700}}>{theObjectData?.theObject?.client?.name}</Typography>
                                <Typography>{theObjectData?.theObject?.client?.address}</Typography>
                                {theObjectData?.theObject?.client?.email && <Typography>{theObjectData?.theObject?.client?.email}</Typography>}
                                {theObjectData?.theObject?.client?.fix && <Typography>{theObjectData?.theObject?.client?.fix}</Typography>}
                                {theObjectData?.theObject?.client?.mobile && <Typography>{theObjectData?.theObject?.client?.mobile}</Typography>}
                            </Paper> : 
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography sx={{fontWeight: 700}}>{theObjectData?.theObject?.partner?.name}</Typography>
                                <Typography>{theObjectData?.theObject?.partner?.address}</Typography>
                                {theObjectData?.theObject?.partner?.email && <Typography>{theObjectData?.theObject?.partner?.email}</Typography>}
                                {theObjectData?.theObject?.partner?.fix && <Typography>{theObjectData?.theObject?.partner?.fix}</Typography>}
                                {theObjectData?.theObject?.partner?.mobile && <Typography>{theObjectData?.theObject?.partner?.mobile}</Typography>}
                            </Paper>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{marginBottom: 3}}> 
                        <Grid item="true" xs={12}>
                            <Typography gutterBottom variant="subtitle1" component="div" sx={{marginBottom: 2}}>
                                <Chip label={theObjectData?.theObject?.name} sx={{fontSize: 20, fontWeight: 700, padding: 3}}/>
                            </Typography>
                            <Paper sx={{ marginBottom: 2}} variant="outlined">
                                <Typography sx={{padding : 1, backgroundColor: '#f1f1f1'}} gutterBottom variant="subtitle3" component="h3">
                                    Historique des récupérations
                                </Typography>
                                <Box sx={{padding : 1 }}>
                                    <ObjectRecoveryTimeline theObjectRecoveries={theObjectData?.theObject?.theObjectRecoveries} />
                                </Box>
                            </Paper>
                            <Paper sx={{ marginBottom: 2}} variant="outlined">
                                <Typography sx={{padding : 1, backgroundColor: '#f1f1f1'}} gutterBottom variant="subtitle3" component="h3">
                                    Description
                                </Typography>
                                <Box sx={{padding : 1, minHeight: 60 }}>
                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{whiteSpace : 'pre-line'}}>
                                        {theObjectData?.theObject?.description}
                                    </Typography>
                                </Box>
                            </Paper>
                            <Paper variant="outlined">
                                <Typography  sx={{padding : 1, backgroundColor: '#f1f1f1'}} gutterBottom variant="subtitle3" component="h3">
                                    Observation
                                </Typography>
                                <Box sx={{padding : 1, minHeight: 60}}>
                                    <Typography gutterBottom variant="subtitle1" component="div" sx={{whiteSpace : 'pre-line'}}>
                                        {theObjectData?.theObject?.observation}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item="true" xs={4}>
                            <Typography>Fait le:</Typography>
                            <Typography>À:</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
        );
}
import React from 'react';

export class FooterDocumentToPrint extends React.PureComponent {
  render() {
    return (<div  className="page-footer">
              
            </div>
    );
  }
}
export default FooterDocumentToPrint;
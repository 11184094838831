import { gql } from '@apollo/client';
import { DATA_BASIC_INFOS } from '../fragments/DataFragment';

export const GET_DATAS = gql`
    query($typeData: String!) {
        datas(typeData : $typeData){
            ...DataBasicInfosFragment
        }
    }
    ${DATA_BASIC_INFOS}
`

export const GET_DATAS_TASK = gql`
  query{
    taskNatures : datas(typeData : "TaskNature"){
        ...DataBasicInfosFragment
    }
  } 
  ${DATA_BASIC_INFOS}
`


export const GET_DATAS_PRODUCT_SERVICE = gql`
  query{
    productServiceNatures : datas(typeData : "TaskNature"){
        ...DataBasicInfosFragment
    }
    productServiceUnits : datas(typeData : "ProductServiceUnit"){
        ...DataBasicInfosFragment
    }
  } 
  ${DATA_BASIC_INFOS}
`
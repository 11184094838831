import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Stack,
  IconButton,
  Checkbox,
  Autocomplete
} from '@mui/material';
import styled from '@emotion/styled';
import TheTextField from '../../../../_shared/components/form-fields/TheTextField';
import TheDateTimePicker from '../../../../_shared/components/form-fields/TheDateTimePicker';
import { CheckBox, CheckBoxOutlineBlank, Close } from '@mui/icons-material';
import { OBJECT_RECOVERY_TYPES } from '../../../../_shared/tools/constants';
import { getRecoveryTypeColor } from '../../../../_shared/tools/functions';

const Item = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const TheObjectFilter = ({ onFilterChange }) => {
    const [selectedTypes, setFilterSelectedTypes] = useState([])
    const [filterValues, setFilterValues] = useState({
        recoveryDate: null,
        returnDate: null,
        keyword: '',
        isRecovered: null,
    });

    const handleFilterSubmit = () => {
        // Pass the filter values to the parent component for handling the filtering logic
        onFilterChange(filterValues);
    };

    const handleFilterClear = () => {
        // Pass the filter values to the parent component for handling the filtering logic
        const filterValuesInit = { recoveryDate: null, returnDate: null, keyword: '', isRecovered: null}
        setFilterSelectedTypes([])
        setFilterValues(filterValuesInit)
        onFilterChange(filterValuesInit);
    };

    return (
        <Grid container spacing={2}>
            <Grid item="true" xs={12} md={4}>
                <Item>
                    <TheTextField variant="outlined"
                        label="Recherche"
                        placeholder="Recherche..."
                        name="keyword"
                        value={filterValues.keyword}
                        onChange={(e) => {setFilterValues({ ...filterValues, keyword: e.target.value }); onFilterChange({ ...filterValues, keyword: e.target.value });}}
                    />
                </Item>
            </Grid>
            <Grid item="true" xs={12} sm={6} md={2}>
                <Item>
                    <TheDateTimePicker
                        label="De"
                        type="date"
                        name="recoveryDate"
                        value={filterValues.recoveryDate}
                        onChange={(e) => setFilterValues({ ...filterValues, recoveryDate: e })}
                    />
                </Item>
            </Grid>
            <Grid item="true" xs={12} sm={6} md={2}>
                <Item>
                    <TheDateTimePicker
                        label="De"
                        name="returnDate"
                        value={filterValues.returnDate}
                        onChange={(e) => setFilterValues({ ...filterValues, returnDate: e })}
                    />
                </Item>
            </Grid>
            <Grid item="true" xs={12} sm={6} md={2}>
                <Item>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={OBJECT_RECOVERY_TYPES?.ALL}
                        disableCloseOnSelect
                        value={selectedTypes}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank sx={{ color : getRecoveryTypeColor(option?.value)}} fontSize="small" />}
                                    checkedIcon={<CheckBox sx={{ color : getRecoveryTypeColor(option?.value)}} fontSize="small" />}
                                    style={{ marginRight: 8}}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        onChange={(event, newValue) => {
                            let isRecovered = null
                            setFilterSelectedTypes(newValue)
                            console.log(newValue)
                            if(newValue?.length === 1) isRecovered =  newValue[0].value === OBJECT_RECOVERY_TYPES.RECOVERY
                            setFilterValues({ ...filterValues, isRecovered })
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Etats" placeholder="Etats" />
                        )}
                    />
                </Item>
            </Grid>
            <Grid item="true" xs={12} md={2} sx={{ display : 'flex', alignItems : 'center'}}>
                <Item  sx={{height: '100%', justifyContent: 'center'}}>
                    <Button variant="outlined" color="info" onClick={handleFilterSubmit}>
                        Chercher
                    </Button>
                </Item>
                <IconButton color="primary" onClick={handleFilterClear}>
                    <Close />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default TheObjectFilter;


import {
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
  import ListProductServices from "./ListProductServices";
  import AddProductService from "./AddProductService";
    
  export default function ProductServices() {
  
    return (
      <div className="online">
          <Routes>
                  <Route path={`liste`} element={<ListProductServices />} />
                  <Route path={`ajouter`} element={<AddProductService />} />
                  <Route path={`modifier/:idProductService`} element={<AddProductService />} />
                  <Route
                      path="/"
                      element={<Navigate to={`liste`} replace />}
                  />
          </Routes>
      </div>
    );
  }
  
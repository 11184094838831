import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack, Box,  Typography, InputAdornment, Button, Divider, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import dayjs from 'dayjs';

import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TheTextField from '../../../../_shared/components/form-fields/TheTextField';
import ImageFileField from '../../../../_shared/components/form-fields/ImageFileField';
import TheDesktopDatePicker from '../../../../_shared/components/form-fields/TheDesktopDatePicker';
import { useFeedBacks } from '../../../../_shared/context/feedbacks/FeedBacksProvider';
import { GET_PRODUCT_SERVICE } from '../../../../_shared/graphql/queries/ProductServiceQueries';
import { POST_PRODUCT_SERVICE, PUT_PRODUCT_SERVICE } from '../../../../_shared/graphql/mutations/ProductServiceMutations';
import ProgressService from '../../../../_shared/services/feedbacks/ProgressService';
import TheSwitch from '../../../../_shared/components/form-fields/theSwitch';
import { GET_DATAS_PRODUCT_SERVICE } from '../../../../_shared/graphql/queries/DataQueries';
import { GET_EMPLOYEES } from '../../../../_shared/graphql/queries/EmployeeQueries';
import { GET_VEHICLES } from '../../../../_shared/graphql/queries/VehicleQueries';
import { GET_MATERIALS } from '../../../../_shared/graphql/queries/MaterialQueries';
import TheAutocomplete from '../../../../_shared/components/form-fields/TheAutocomplete';
import { Close } from '@mui/icons-material';
import SelectCheckmarks from '../../../../_shared/components/form-fields/SelectCheckmarks';

const Item = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AddProductServiceForm({ idProductService, title}) {
    const  { setNotifyAlert,  setConfirmDialog} = useFeedBacks();
    const navigate = useNavigate();
    const validationSchema = yup.object({
        name: yup .string('Entrez le nom du service').required('Le nom du service est obligatoire'),
      });
    const formik = useFormik({
        initialValues: {
            image : undefined,  number : '', name : '',
            isBlocked : false, designation : '',
            sellingPriceHt : 0, tva : 0, productServiceUnit: null, description : '',
            observation : '', isActive : true,
            productServiceNatures: [], otherProductServiceNatures : '',
            workersInfos : '', vehiclesInfos : '', materialsInfos : '',
            workers : [],  vehicles : [],  materials : [], productServiceChecklist: [{ localisation: '', comment: '', description: '' }]
          },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let { image , ...productServiceCopy } = values
            productServiceCopy.workers = productServiceCopy.workers.map(i => i?.id)
            productServiceCopy.vehicles = productServiceCopy.vehicles.map(i => i.id)
            productServiceCopy.materials = productServiceCopy.materials.map(i => i.id)
            productServiceCopy.productServiceNatures = productServiceCopy.productServiceNatures.map(i => i?.id)
            if(idProductService && idProductService != ''){
                onUpdateProductService({ 
                    id : productServiceCopy.id, 
                    productServiceData : productServiceCopy,
                    image : image,
                    }
                )
            }
            else createProductService({ 
                    variables: { 
                        productServiceData : productServiceCopy,
                        image : image,
                    } 
                })
        },
    });
    const { 
        loading: loadingEmployees, 
        data: employeesData, 
        error: employeesError, 
        fetchMore:  fetchMoreEmployees 
      } = useQuery(GET_EMPLOYEES, { fetchPolicy: "network-only"})
    const { 
        loading: loadingVehicles, 
        data: vehiclesData, 
        error: vehiclesError, 
        fetchMore:  fetchMoreVehicles 
      } = useQuery(GET_VEHICLES, { fetchPolicy: "network-only"}) 
    const { 
        loading: loadingMaterials, 
        data: materialsData, 
        error: materialsError, 
        fetchMore:  fetchMoreMaterials 
    } = useQuery(GET_MATERIALS, { fetchPolicy: "network-only"})
    
    const { 
        loading: loadingDatas, 
        data: dataData, 
        error: datsError, 
        fetchMore:  fetchMoreDatas  
    } = useQuery(GET_DATAS_PRODUCT_SERVICE, { fetchPolicy: "network-only" })

    const [createProductService, { loading : loadingPost }] = useMutation(POST_PRODUCT_SERVICE, {
        onCompleted: (data) => {
            console.log(data);
            setNotifyAlert({
                isOpen: true,
                message: 'Ajouté avec succès',
                type: 'success'
            })
            let { __typename, ...productServiceCopy } = data.createProductService.productService;
        //   formik.setValues(productServiceCopy);
            navigate('/online/ventes/services/liste');
        },
        update(cache, { data: { createProductService } }) {
            const newProductService = createProductService.productService;
        
            cache.modify({
              fields: {
                productServices(existingProductServices = { totalCount: 0, nodes: [] }) {
                    return {
                        totalCount: existingProductServices.totalCount + 1,
                        nodes: [newProductService, ...existingProductServices.nodes],
                    };
                },
              },
            });
        },
        onError: (err) => {
            console.log(err)
            setNotifyAlert({
                isOpen: true,
                message: 'Non ajouté ! Veuillez réessayer.',
                type: 'error'
            })
        },
    })
    const [updateProductService, { loading : loadingPut }] = useMutation(PUT_PRODUCT_SERVICE, {
        onCompleted: (data) => {
            console.log(data);
            setNotifyAlert({
                isOpen: true,
                message: 'Modifié avec succès',
                type: 'success'
            })
            let { __typename, ...productServiceCopy } = data.updateProductService.productService;
            //   formik.setValues(productServiceCopy);
            navigate('/online/ventes/services/liste');
        },
        update(cache, { data: { updateProductService } }) {
            const updatedProductService = updateProductService.productService;
          
            cache.modify({
              fields: {
                productServices(existingProductServices = { totalCount: 0, nodes: [] }, { readField }) {
                    
                    const updatedProductServices = existingProductServices.nodes.map((productService) =>
                        readField('id', productService) === updatedProductService.id ? updatedProductService : productService
                    );
            
                    return {
                        totalCount: existingProductServices.totalCount,
                        nodes: updatedProductServices,
                    };
                },
              },
            });
        },
        onError: (err) => {
            console.log(err)
            setNotifyAlert({
                isOpen: true,
                message: 'Non modifié ! Veuillez réessayer.',
                type: 'error'
            })
        },
    })
    const onUpdateProductService = (variables) => {
        setConfirmDialog({
          isOpen: true,
          title: 'ATTENTION',
          subTitle: "Voulez vous vraiment modifier ?",
          onConfirm: () => { setConfirmDialog({isOpen: false})
                updateProductService({ variables })
            }
        })
      }
    const [getProductService, { loading : loadingProductService }] = useLazyQuery(GET_PRODUCT_SERVICE, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            let { __typename, ...productServiceCopy1 } = data.productService;
            let { folder, ...productServiceCopy } = productServiceCopy1;
            productServiceCopy.productServiceUnit = productServiceCopy.productServiceUnit ? Number(productServiceCopy.productServiceUnit.id) : null
            productServiceCopy.workers = productServiceCopy.workers ? productServiceCopy.workers.map(i => i?.employee) : []
            productServiceCopy.vehicles = productServiceCopy.vehicles ? productServiceCopy.vehicles.map(i => i?.vehicle) : []
            productServiceCopy.materials = productServiceCopy.materials ? productServiceCopy.materials.map(i => i?.material) : []
            if(!productServiceCopy?.productServiceChecklist) productServiceCopy['productServiceChecklist'] = [];
            let items = []
            productServiceCopy.productServiceChecklist.forEach((item) =>{
                let { __typename, ...itemCopy } = item;
                items.push(itemCopy)
            })
            productServiceCopy.productServiceChecklist = items;
            formik.setValues(productServiceCopy);
        },
        onError: (err) => console.log(err),
    })
    React.useEffect(()=>{
        if(idProductService){
            getProductService(({ variables: { id: idProductService } }));
        }
    }, [idProductService])
    const addChecklistItem = () => {
        formik.setValues({
          ...formik.values,
          productServiceChecklist: [...formik.values.productServiceChecklist, { localisation: '', comment: '', description: '' }]
        });
      };
    
      const removeChecklistItem = (index) => {
        const updatedChecklist = [...formik.values.productServiceChecklist];
        updatedChecklist.splice(index, 1);
    
        formik.setValues({
          ...formik.values,
          productServiceChecklist: updatedChecklist
        });
      };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography component="div" variant="h5">
                {title} {formik.values.number}
            </Typography>
            { loadingProductService && <ProgressService type="form" />}
            { !loadingProductService &&
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={2} sm={4} md={4}>
                            <Item>
                                <TheTextField variant="outlined" label="Référence"
                                    value={formik.values.number}
                                    disabled
                                />
                            </Item>
                            <Item>
                                <ImageFileField variant="outlined" label="Image"
                                    imageValue={formik.values.image}
                                    onChange={(imageFile) => formik.setFieldValue('image', imageFile)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={2} sm={4} md={4}>
                            <Item>
                                <TheTextField variant="outlined" label="Libellé" id="name"
                                    value={formik.values.name} required
                                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                            {/* <Item>
                                <TheTextField variant="outlined" label="Quantité"
                                    value={formik.values.quantity} type="number"
                                    onChange={(e) => formik.setFieldValue('quantity', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item> */}
                            <Item>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Unité</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Unitét"
                                        value={formik.values.productServiceUnit}
                                        onChange={(e) => formik.setFieldValue('productServiceUnit', e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Choisissez une unité</em>
                                        </MenuItem>
                                        {
                                            dataData?.productServiceUnits?.map((data, index) =>
                                                {
                                                    return <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid xs={2} sm={4} md={4}>
                            <Item>
                                <TheTextField variant="outlined" label="Prix de vente HT d'unité"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                    value={formik.values.sellingPriceHt}
                                    onChange={(e) => formik.setFieldValue('sellingPriceHt', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                            <Item>
                                <TheTextField variant="outlined" label="Tva %"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    value={formik.values.tva}
                                    onChange={(e) => formik.setFieldValue('tva', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid xs={2} sm={6} md={6}>
                            <Item>
                                <TheTextField variant="outlined" label="Désignation"
                                    value={formik.values.designation}
                                    onChange={(e) => formik.setFieldValue('designation', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                        </Grid>
                        <Grid xs={2} sm={2} md={2}>
                            <Item>
                                <TheSwitch variant="outlined" label="Bloqué"
                                    checked={formik.values.isBlocked}
                                    value={formik.values.isBlocked}
                                    onChange={(e) => formik.setFieldValue('isBlocked', e.target.checked)}
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item="true">
                            <Item>
                                <SelectCheckmarks  options={dataData?.productServiceNatures} label="Nature"
                                    placeholder="Ajouter une nature"
                                    limitTags={3}
                                    value={formik.values.productServiceNatures}
                                    onChange={(e, newValue) => formik.setFieldValue('productServiceNatures', newValue)}/>
                            </Item>
                            <Item>
                                <TheTextField variant="outlined" label="Autres natures"
                                    value={formik.values.otherProductServiceNatures}
                                    onChange={(e) => formik.setFieldValue('otherProductServiceNatures', e.target.value)}
                                    helperText="Si vous ne trouvez pas la nature dans la liste dessus."
                                    disabled={loadingPost || loadingPut}
                                />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item="true">
                            <Divider variant="middle"/>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item="true">
                            <Typography component="div" variant="h6">
                                Les tâches à traiter
                            </Typography>
                            
                            {formik.values?.productServiceChecklist?.map((item, index) => (
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} key={index}>
                                    <Grid xs={12} sm={4} md={4} item="true">
                                        <Item>
                                            <TheTextField variant="outlined" label="Localisation"
                                                value={item.localisation}
                                                onChange={(e) => formik.setFieldValue(`productServiceChecklist.${index}.localisation`, e.target.value)}
                                                disabled={loadingPost || loadingPut}
                                                />
                                        </Item>
                                    </Grid>
                                    <Grid xs={12} sm={8} md={8} item="true">
                                        <Item>
                                            <TheTextField variant="outlined" label="Description" multiline rows={4}
                                                value={item.description}
                                                onChange={(e) => formik.setFieldValue(`productServiceChecklist.${index}.description`, e.target.value)}
                                                disabled={loadingPost || loadingPut}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                                    <IconButton onClick={() => removeChecklistItem(index)} edge="end"><Close /></IconButton>
                                                                </InputAdornment>
                                                }}
                                                />
                                        </Item>
                                    </Grid>
                                    {/* <Grid xs={12} sm={4} md={4} item="true">
                                        <Item>
                                            <TheTextField variant="outlined" label="Commentaire" multiline rows={4}
                                                value={item.comment}
                                                onChange={(e) => formik.setFieldValue(`productServiceChecklist.${index}.comment`, e.target.value)}
                                                disabled={loadingPost || loadingPut}
                                                />
                                        </Item>
                                    </Grid> */}
                                </Grid>
                            ))}
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item="true" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" size="small"  onClick={addChecklistItem}
                            disabled={loadingPost || loadingPut}>Ajouter un élément</Button>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item="true">
                            <Divider variant="middle" />
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item="true">
                            <Typography gutterBottom variant="subtitle3" component="h3">
                                Les intervenants
                            </Typography>
                            <Item>
                                <TheAutocomplete options={employeesData?.employees?.nodes} label="Intervenants"
                                    placeholder="Ajouter un intérvenant"
                                    limitTags={2}
                                    value={formik.values.workers}
                                    onChange={(e, newValue) => formik.setFieldValue('workers', newValue)}
                                    onBlur={()=> formik.touched.workers = true}
                                    error={formik.touched.workers && Boolean(formik.values.workers.length < 1)}
                                    helperText={formik.touched.workers && Boolean(formik.values.workers.length < 1) && 'Au moins un intérvenant'}/>
                            </Item>
                            <Item>
                                <TheTextField variant="outlined" label="Infos intervenants" multiline rows={6}
                                    value={formik.values.workersInfos}
                                    onChange={(e) => formik.setFieldValue('workersInfos', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item="true">
                            <Typography gutterBottom variant="subtitle3" component="h3">
                                Les véhicules
                            </Typography>
                            <Item>
                                <TheAutocomplete options={vehiclesData?.vehicles?.nodes} label="Véhicules"
                                    placeholder="Ajouter un véhicule"
                                    limitTags={2}
                                    value={formik.values.vehicles}
                                    onChange={(e, newValue) => formik.setFieldValue('vehicles', newValue)}/>
                            </Item>
                            <Item>
                                <TheTextField variant="outlined" label="Infos véhicules" multiline rows={6}
                                    value={formik.values.vehiclesInfos}
                                    onChange={(e) => formik.setFieldValue('vehiclesInfos', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} item="true">
                            <Typography gutterBottom variant="subtitle3" component="h3">
                                Les matériels
                            </Typography>
                            <Item>
                                <TheAutocomplete options={materialsData?.materials?.nodes} label="Matériels"
                                    placeholder="Ajouter un matérial"
                                    limitTags={2}
                                    value={formik.values.materials}
                                    onChange={(e, newValue) => formik.setFieldValue('materials', newValue)}/>
                            </Item>
                            <Item>
                                <TheTextField variant="outlined" label="Infos matériels" multiline rows={6}
                                    value={formik.values.materialsInfos}
                                    onChange={(e) => formik.setFieldValue('materialsInfos', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item="true">
                            <Divider variant="middle" />
                        </Grid>
                        <Grid xs={12} sm={6} md={6}>
                            <Item>
                                <TheTextField variant="outlined" label="Description" multiline rows={4}
                                    value={formik.values.description}
                                    onChange={(e) => formik.setFieldValue('description', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={6} md={6}>
                            <Item>
                                <TheTextField variant="outlined" label="Observation" multiline rows={4}
                                    value={formik.values.observation}
                                    onChange={(e) => formik.setFieldValue('observation', e.target.value)}
                                    disabled={loadingPost || loadingPut}
                                    />
                            </Item>
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <Item sx={{ justifyContent: 'end', flexDirection : 'row' }}>
                                <Link to="/online/ventes/services/liste" className="no_style">
                                    <Button variant="outlined" sx={{ marginRight : '10px' }}>Annuler</Button>
                                </Link>
                                <Button type="submit" variant="contained" disabled={!formik.isValid || loadingPost || loadingPut}>Valider</Button>
                            </Item>
                        </Grid>
                    </Grid>
                </form>
            }
        </Box>
    );
}

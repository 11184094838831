
import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, Stack, Box, Divider, Chip } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { GET_TASK_RECAP } from '../../../../../_shared/graphql/queries/TaskQueries';
import ProgressService from '../../../../../_shared/services/feedbacks/ProgressService';
import { getFormatDate, getFormatDateTime, getStepTypeLabel } from '../../../../../_shared/tools/functions';
import { useSession } from '../../../../../_shared/context/SessionProvider';
import { useFeedBacks } from '../../../../../_shared/context/feedbacks/FeedBacksProvider';
import { PUT_TASK_FIELDS } from '../../../../../_shared/graphql/mutations/TaskMutations';
import TheTextField from '../../../../../_shared/components/form-fields/TheTextField';

export default function TaskReport({task}) {
    const  { setNotifyAlert,  setConfirmDialog} = useFeedBacks();
    const [hideInput, setHideInput] = useState(true);
    const { user } = useSession();
    const validationSchema = yup.object({
        name: yup.string("Entrez le nom de l'intervention").required("Le nom de l'intervention est obligatoire")
      });
    const formik = useFormik({
        initialValues: {
            report : '', observation : '',
          },
        validationSchema: validationSchema,
        onSubmit: (values) => {
        },
    });
    const [getTask, { 
        loading : loadingTask,
        data: taskData, 
        error: taskError, 
      }] = useLazyQuery(GET_TASK_RECAP)
    React.useEffect(()=>{
        if(task?.id){
            getTask(({ variables: { id: task.id } }));
        }
    }, [task])
    React.useEffect(()=>{
        if(taskData?.task){
            formik.setFieldValue('report', taskData?.task?.report)
            formik.setFieldValue('observation', taskData?.task?.observation)
        }
    }, [])

    
    const onDisplayInput = () => {
        // if(hideInput){
        //     setHideInput(false)
        //     if(taskData?.task){
        //         formik.setFieldValue('report', taskData?.task?.report)
        //         formik.setFieldValue('observation', taskData?.task?.observation)
        //     }
        // }
    };

    const [updateTaskFields, { loading : loadingPut }] = useMutation(PUT_TASK_FIELDS, {
        onCompleted: (datas) => {
            setHideInput(true)
            if(datas.updateTaskFields.done){
                // setTask({...task, observation : datas.updateTaskFields.task.observation})
            }else{
                setNotifyAlert({
                    isOpen: true,
                    message: `Non changée ! ${datas.updateTaskFields.message}.`,
                    type: 'error'
                })
            } 
        },
        update(cache, { data: { updateTaskFields } }) {
            cache.modify({
                id: cache.identify({ __typename: 'Task', id: updateTaskFields?.task.id }),
                fields: {
                  task(existingTask = null) {
                    if (existingTask) {
                      return {
                        ...existingTask,
                        report: updateTaskFields?.task.report,
                        observation: updateTaskFields?.task.observation,
                      };
                    }
                    return null;
                  },
                },
              });
        },
        onError: (err) => {
            setHideInput(true)
            console.log(err)
            setNotifyAlert({
                isOpen: true,
                message: 'Non changée ! Veuillez réessayer.',
                type: 'error'
            })
        },
    })
    const handleClickUpdateTaskFields = (taskFields) => {
        // Naviguez vers l'écran de détails avec les informations de l'intervention
        setConfirmDialog({
            isOpen: true,
            title: 'ATTENTION',
            subTitle: "Voulez vous vraiment modifier ?",
            onConfirm: () => { setConfirmDialog({isOpen: false})
                updateTaskFields({variables : { id : task.id, taskFields}})
              }
          })
          setHideInput(true)
    };

    
    
    if(loadingTask) return <ProgressService type="form" />
    return (
            <Stack sx={{ height : '100%', alignItems : 'center', justifyContent : 'center'}}>
                <Paper elevation={0} sx={{ width: '100%'}}>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                    <Box>
                                        {user?.company?.logo && <img src={user?.company?.logo} alt={user?.company?.name} width={120} />}
                                    </Box>
                                    <Box sx={{ paddingLeft: 1}}>
                                        {user?.company?.name && user?.company?.name !== '' && <Typography><b>{user?.company?.name}</b></Typography>}
                                        {user?.company?.address && user?.company?.address !== '' && <Typography>{user?.company?.address}</Typography>}
                                        {user?.company?.fix && user?.company?.fix !== '' && <Typography>{user?.company?.fix}</Typography>}
                                        {user?.company?.mobile && user?.company?.mobile !== '' && <Typography>{user?.company?.mobile}</Typography>}
                                        {user?.company?.email && user?.company?.email !== '' && <Typography>{user?.company?.email}</Typography>}
                                    </Box>
                                </Box>
                            </Paper>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography>Date d'intervention : <b>{`${getFormatDate(taskData?.task?.startingDateTime)}`}</b></Typography>
                                <Typography>Adresse d'intervention : <b>{taskData?.task?.address}</b></Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography sx={{ fontWeight: 700 }}>Compte rendu d'intervention n° <b>{taskData?.task?.number}</b></Typography>
                            </Paper>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography sx={{fontWeight: 700}}>{taskData?.task?.client?.name}</Typography>
                                <Typography>{taskData?.task?.client?.address}</Typography>
                                {taskData?.task?.client?.email && <Typography>{taskData?.task?.client?.email}</Typography>}
                                {taskData?.task?.client?.fix && <Typography>{taskData?.task?.client?.fix}</Typography>}
                                {taskData?.task?.client?.mobile && <Typography>{taskData?.task?.client?.mobile}</Typography>}
                            </Paper>
                            <Paper sx={{ padding : 2, marginBottom: 2}} variant="outlined">
                                <Typography gutterBottom variant="subtitle1" component="div">
                                Nature de l'intervention:
                                </Typography>
                                {taskData?.task?.taskNatures?.map((taskNature, index)=> <Chip sx={{marginRight: 2}} key={index} label={taskNature?.name}/>)}
                                {taskData?.task?.otherTaskNatures && taskData?.task?.otherTaskNatures !== '' && <Chip label={`Autres: ${taskData?.task?.otherTaskNatures}`}/>}
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{marginBottom: 3}}> 
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                <b>{taskData?.task?.name}</b>
                            </Typography>
                            <Paper sx={{ marginBottom: 2}} variant="outlined">
                                <Typography sx={{padding : 1, backgroundColor: '#f1f1f1'}} gutterBottom variant="subtitle3" component="h3">
                                    Rapport d'intervention
                                </Typography>
                                <Box sx={{padding : 1, minHeight: 100 }} onClick={()=>onDisplayInput()}>
                                    {hideInput && <Typography gutterBottom variant="subtitle1" component="div" sx={{whiteSpace : 'pre-line'}}>
                                        {taskData?.task?.report}
                                    </Typography>}
                                    {!hideInput && <TheTextField fullWidth variant="outlined"  multiline rows={6}
                                        value={formik.values.report}
                                        onChange={(e) => formik.setFieldValue('report', e.target.value)}
                                        // onBlur={()=> handleClickUpdateTaskFields({report : formik.values.report, observation : formik.values.observation})}
                                        disabled={loadingPut}
                                    />}
                                </Box>
                            </Paper>
                            <Paper variant="outlined">
                                <Typography  sx={{padding : 1, backgroundColor: '#f1f1f1'}} gutterBottom variant="subtitle3" component="h3">
                                    Observation
                                </Typography>
                                <Box sx={{padding : 1}} onClick={()=> onDisplayInput()}>
                                    {hideInput && <Typography gutterBottom variant="subtitle1" component="div" sx={{whiteSpace : 'pre-line'}}>
                                        {taskData?.task?.observation}
                                    </Typography>}
                                    {!hideInput && <TheTextField fullWidth variant="outlined" multiline rows={6}
                                        value={formik.values.observation}
                                        onChange={(e) => formik.setFieldValue('observation', e.target.value)}
                                        // onBlur={()=> handleClickUpdateTaskFields({report : formik.values.report, observation : formik.values.observation})}
                                        disabled={loadingPut}
                                    />}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography>Fait le:</Typography>
                            <Typography>À:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Signature de l'intervenant:</Typography>
                            {taskData?.task?.employeeSignature && <>
                                <Typography sx={{fontWeight: 700}}>{`${taskData?.task?.employeeSignature?.creator?.firstName} ${taskData?.task?.employeeSignature?.creator?.lastName}`}</Typography>
                                <img src={taskData?.task?.employeeSignature?.base64Encoded} width={100} alt="Signature de l'intervenant" title="Signature de l'intervenant" />
                            </>}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Signature du client: </Typography>
                            {taskData?.task?.clientSignature && <>
                                <Typography sx={{fontWeight: 700}}> {taskData?.task?.clientSignature?.authorName}</Typography>
                                <img src={taskData?.task?.clientSignature?.base64Encoded} width={100} alt="Signature de l'intervenant" title="Signature de l'intervenant" />
                            </>}
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
        );
}
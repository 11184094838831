import { gql } from '@apollo/client';
import { TASK_BASIC_INFOS } from '../fragments/TaskFragment';

export const POST_TASK = gql`
  mutation CreateTask($taskData: TaskInput!, $image : Upload, $quote : Upload) {
    createTask(taskData: $taskData, image : $image, quote : $quote) {
      task{
        ...TaskBasicInfosFragment
      }
    }
  }
  ${TASK_BASIC_INFOS}
`;

export const PUT_TASK = gql`
  mutation UpdateTask($id: ID!, $taskData: TaskInput!, $image : Upload, $quote : Upload) {
    updateTask(id: $id, taskData: $taskData, image : $image, quote : $quote) {
      task{
        ...TaskBasicInfosFragment
      }
    }
  }
  ${TASK_BASIC_INFOS}
`;

export const PUT_TASK_FIELDS = gql`
  mutation updateTaskFields($id: ID!, $taskFields: TaskFieldInput!) {
    updateTaskFields(id: $id, taskFields: $taskFields){
      done
      success
      message
      task{
        ...TaskBasicInfosFragment
        report
        observation
      }
    }
  }
  ${TASK_BASIC_INFOS}
`;


export const PUT_TASK_STATE = gql`
  mutation UpdateTaskState($id: ID!) {
    updateTaskState(id: $id){
      done
      success
      message
      task{
        ...TaskBasicInfosFragment
      }
    }
  }
  ${TASK_BASIC_INFOS}
`;

export const PUT_TASK_PRINT_COUNT = gql`
  mutation UpdateTaskPrintCount($id: ID!) {
    updateTaskPrintCount(id: $id){
      done
      success
      message
      task{
        ...TaskBasicInfosFragment
      }
    }
  }
  ${TASK_BASIC_INFOS}
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id){
      id
      success
      deleted
      message
    }
  }
`;

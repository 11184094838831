import React from 'react';

export class HeaderDocumentToPrint extends React.PureComponent {
  render() {
    return (<div>
              
            </div>
    );
  }
}
export default HeaderDocumentToPrint;
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { getFormatDateTime, getObjectRecoveryTypeLabel } from '../../../../../_shared/tools/functions';
import { OBJECT_RECOVERY_TYPES } from '../../../../../_shared/tools/constants';
import { Box } from '@mui/material';

export default function ObjectRecoveryTimeline({theObjectRecoveries}) {
  return (
    <Timeline position="alternate">
        
        {theObjectRecoveries?.map((theObjectRecovery, index) => (
            <TimelineItem key={index}>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                <Typography variant="body2" color="text.secondary">
                    {theObjectRecovery?.objectRecoveryType === OBJECT_RECOVERY_TYPES.RECOVERY ? 
                        <><b>Récupéré le:</b> {getFormatDateTime(theObjectRecovery?.recoveryDate) + ' '}</> :
                        <><b>Retourné le:</b> {getFormatDateTime(theObjectRecovery?.returnDate) + ' '}</>
                    }
                     par  <b>{theObjectRecovery?.creator?.firstName}</b>
                <Typography>
                    Persone sur place: <b>{theObjectRecovery?.receiverName}</b>
                </Typography>
                </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot  color={theObjectRecovery?.objectRecoveryType === OBJECT_RECOVERY_TYPES.RECOVERY ? 'inherit' : 'primary'}>
                    <RepeatIcon />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {`${getObjectRecoveryTypeLabel(theObjectRecovery?.objectRecoveryType)}`}
                </Typography>
                <Box>
                    <TitlebarBelowMasonryImageList 
                        images={theObjectRecovery?.images}
                        direction={index%2 === 0 ? 'inherit' : 'rtl'}/>
                </Box>
                <Typography>{theObjectRecovery?.comment}</Typography>
                </TimelineContent>
            </TimelineItem>
        ))}
    </Timeline>
  );
}

function TitlebarBelowMasonryImageList({images, direction}) {
  return (
    <Box>
      <ImageList variant="masonry" cols={3} gap={3} style={{direction}}>
        {images?.map((image, index) => (
          <ImageListItem key={index}>
            <img
              srcSet={`${image?.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${image?.image}?w=248&fit=crop&auto=format`}
              alt={image?.caption}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}



import { gql } from '@apollo/client';
import { PRODUCT_SERVICE_BASIC_INFOS, PRODUCT_SERVICE_DETAILS } from '../fragments/ProductServiceFragment';

export const GET_PRODUCT_SERVICE = gql`
  query GetProductService($id: ID!) {
    productService(id: $id) {
      ...ProductServiceDetailsFragment
    }
  }
  ${PRODUCT_SERVICE_DETAILS}
`;

export const GET_PRODUCT_SERVICES = gql`
  query GetProductServices($productServiceFilter: ProductServiceFilterInput, $offset: Int, $limit: Int, $page: Int){
    productServices(productServiceFilter: $productServiceFilter, offset : $offset, limit : $limit, page : $page){
      totalCount
      nodes{
        ...ProductServiceBasicInfosFragment
      }
    }
  }
  ${PRODUCT_SERVICE_BASIC_INFOS}
`;

// Add more productService-related queries here

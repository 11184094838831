// ProductServiceFragment.js

import { gql } from '@apollo/client';
import { FOLDER_MINI_INFOS } from './MediaFragment';
import { VEHICLE_BASIC_INFOS } from './VehicleFragment';
import { MATERIAL_BASIC_INFOS } from './MaterialFragment';
import { EMPLOYEE_BASIC_INFOS } from './EmployeeFragment';

export const PRODUCT_SERVICE_BASIC_INFOS = gql`
  fragment ProductServiceBasicInfosFragment on ProductServiceType {
    id,
    number
    name
    image
    isBlocked
    designation
    isActive
    folder{
      ...FolderMiniInfosFragment
    }
    sellingPriceHt
    productServiceUnit{
      id
      name
    }
  }
  ${FOLDER_MINI_INFOS}
`

export const PRODUCT_SERVICE_CHECK_LIST_DETAILS = gql`
  fragment ProductServiceChecklistItemFragment on ProductServiceChecklistItemType {
    id
    name
    localisation
    comment
    description
  }
`;

export const PRODUCT_SERVICE_WORKER_DETAILS = gql`
  fragment ProductServiceWorkerTypeFragment on ProductServiceWorkerType {
    id
    employee{
      ...EmployeeBasicInfosFragment
    }
  }
  ${EMPLOYEE_BASIC_INFOS}
`;

export const PRODUCT_SERVICE_VEHICLE_DETAILS = gql`
  fragment ProductServiceVehicleTypeFragment on ProductServiceVehicleType {
    id
    vehicle{
      ...VehicleBasicInfosFragment
    }
  }
  ${VEHICLE_BASIC_INFOS}
`;

export const PRODUCT_SERVICE_MATERIAL_DETAILS = gql`
  fragment ProductServiceMaterialTypeFragment on ProductServiceMaterialType {
    id
    material{
      ...MaterialBasicInfosFragment
    }
  }
  ${MATERIAL_BASIC_INFOS}
`;



export const PRODUCT_SERVICE_DETAILS = gql`
  fragment ProductServiceDetailsFragment on ProductServiceType {
    ...ProductServiceBasicInfosFragment
    tva
    description
    observation
    workersInfos
    vehiclesInfos
    materialsInfos
    productServiceNatures{
      id
      name
    }
    otherProductServiceNatures
    workers{
      ...ProductServiceWorkerTypeFragment
    }
    vehicles{
      ...ProductServiceVehicleTypeFragment
    }
    materials{
      ...ProductServiceMaterialTypeFragment
    }
    productServiceChecklist{
      ...ProductServiceChecklistItemFragment
    }
  }
  ${PRODUCT_SERVICE_BASIC_INFOS}
  ${PRODUCT_SERVICE_WORKER_DETAILS}
  ${PRODUCT_SERVICE_VEHICLE_DETAILS}
  ${PRODUCT_SERVICE_MATERIAL_DETAILS}
  ${PRODUCT_SERVICE_CHECK_LIST_DETAILS}
`;

import * as React from 'react';
import Table from '@mui/material/Table';
import { Alert, Avatar, AvatarGroup, Chip, IconButton, MenuItem, Popover, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { getFormatTime, getStatusColor, getStatusLabel } from '../../../../_shared/tools/functions';
import { Link } from 'react-router-dom';
import { Article, Delete, Edit, Folder, MoreVert } from '@mui/icons-material';
import { useFeedBacks } from '../../../../_shared/context/feedbacks/FeedBacksProvider';

function TaskItemRow({ task }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const  { setDialogListLibrary } = useFeedBacks();
    const onOpenDialogListLibrary = (folderParent) => {
        setDialogListLibrary({
        isOpen: true,
        folderParent,
        onClose: () => { 
            setDialogListLibrary({isOpen: false})
            }
        })
    }
  return (
    <React.Fragment>
            <TableRow key={task?.id}>
              <TableCell>{task?.name}</TableCell>
              <TableCell>À {`${getFormatTime(task?.startingDateTime)}`}</TableCell>
              <TableCell>
                {task?.client && <Tooltip title={task?.client?.name}>
                                    <Link to={`/online/ventes/clients/details/${task?.client?.id}`} className="no_style">
                                      <Chip
                                          avatar={<Avatar alt={task?.client?.name} src={task?.client?.photo} />}
                                          label={task?.client?.name}
                                          variant="outlined"
                                        />
                                    </Link>
                                  </Tooltip>}
                {!task?.client && <Chip
                  label={task?.clientName}
                  variant="outlined"
                />}
              </TableCell>
              <TableCell  align="left">
                <AvatarGroup max={3} sx={{justifyContent: 'start'}}>
                {
                  task?.workers?.map((worker, index) => (
                    <Tooltip title={`${worker?.employee?.firstName} ${worker?.employee?.lastName}`} >
                      <Link to={`/online/employes/details/${worker?.employee?.id}`} className="no_style">
                        <Avatar alt={`${worker?.employee?.firstName} ${worker?.employee?.lastName}`} src={worker?.employee?.photo} />
                      </Link>
                    </Tooltip>
                  ))
                }
                </AvatarGroup>
              </TableCell>
              <TableCell>{task?.address}</TableCell>
              <TableCell align="right">
                <Chip label={getStatusLabel(task?.status)} sx={{ backgroundColor : getStatusColor(task?.status), color : '#ffffff'}}/>
              </TableCell>
              <TableCell align="right">
                  <IconButton onClick={handleOpenMenu}>
                      <MoreVert />
                  </IconButton>
                  <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                      <Link to={`/online/travaux/interventions/details/${task?.id}`} className="no_style">
                          <MenuItem onClick={handleCloseMenu}>
                              <Article sx={{ mr: 2 }} />
                              Détails
                          </MenuItem>
                      </Link>
                      <MenuItem onClick={ ()=> {onOpenDialogListLibrary(task?.folder); handleCloseMenu()}}>
                          <Folder sx={{ mr: 2 }} />
                          Bibliothèque
                      </MenuItem>
                      <Link to={`/online/travaux/interventions/modifier/${task?.id}`} className="no_style">
                        <MenuItem onClick={handleCloseMenu}>
                            <Edit sx={{ mr: 2 }} />
                            Modifier
                        </MenuItem>
                      </Link>
                  </Popover>
              </TableCell>
            </TableRow>
    </React.Fragment>
  );
}

export default function Tasks({ tasks = [] }) {
  return (
    <React.Fragment>
      <Title>Les interventions d'aujourd'hui</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Titre</TableCell>
            <TableCell>Heure</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Intervenants</TableCell>
            <TableCell>Adresse</TableCell>
            <TableCell align="right">Etat</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks?.map((task) => (
            <TaskItemRow key={task?.id} task={task} />
          ))}
        </TableBody>
      </Table>
      { tasks?.length < 1 && <Alert severity="warning">Aucune intervention pour aujourd'hui.</Alert> }
    </React.Fragment>
  );
}
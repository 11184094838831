import { gql } from '@apollo/client';
import { PRODUCT_SERVICE_BASIC_INFOS } from '../fragments/ProductServiceFragment';

export const POST_PRODUCT_SERVICE = gql`
  mutation CreateProductService($productServiceData: ProductServiceInput!, $image : Upload) {
    createProductService(productServiceData: $productServiceData, image : $image) {
      productService{
        ...ProductServiceBasicInfosFragment
      }
    }
  }
  ${PRODUCT_SERVICE_BASIC_INFOS}
`;

export const PUT_PRODUCT_SERVICE = gql`
  mutation UpdateProductService($id: ID!, $productServiceData: ProductServiceInput!, $image : Upload) {
    updateProductService(id: $id, productServiceData: $productServiceData, image : $image) {
      productService{
        ...ProductServiceBasicInfosFragment
      }
    }
  }
  ${PRODUCT_SERVICE_BASIC_INFOS}
`;

export const PUT_PRODUCT_SERVICE_STATE = gql`
  mutation UpdateProductServiceState($id: ID!) {
    updateProductServiceState(id: $id){
      done
      success
      message
      productService{
        ...ProductServiceBasicInfosFragment
      }
    }
  }
  ${PRODUCT_SERVICE_BASIC_INFOS}
`;

export const DELETE_PRODUCT_SERVICE = gql`
  mutation DeleteProductService($id: ID!) {
    deleteProductService(id: $id){
      id
      success
      deleted
      message
    }
  }
`;
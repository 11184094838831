import React from 'react';
import { useTheme, Stack, Box, IconButton, Dialog, DialogContent, Tooltip } from '@mui/material';
import { Close, Print } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print';
import DocumentToPrint from './DocumentToPrint';import { PUT_TASK_PRINT_COUNT } from '../../../../_shared/graphql/mutations/TaskMutations';
import { useMutation } from '@apollo/client';
import { GET_TASKS } from '../../../../_shared/graphql/queries/TaskQueries';
;

export default function PrintingModal({ printingModal, setPrintingModal }) {
  let {isOpen, onClose, type, data } = printingModal
  const theme = useTheme();

  const componentRef = React.useRef();
  const pageStyle = `
    @page{ 
      size: auto;  margin: 5mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
      @media print {
        div.page-footer {
          position: fixed;
          bottom:0mm;
          width: 100%;
          height: 110px;
          /*font-size: 15px;*/
          /*color: #fff;*/
          /* For testing */
          /*background: red; */
          opacity: 0.7;
          
          page-break-after: always;
        }
        .page-number:before {
          /* counter-increment: page; */
          content: "Pagina "counter(page);
        }
      }
      body {
        marginBottom:125px
      }
    `;

  const [updateTaskPrintCount, { loading : loadingPrinCount }] = useMutation(PUT_TASK_PRINT_COUNT, {
    onCompleted: (datas) => {
      if(datas.updateTaskPrintCount.done){
        console.log('updateTaskPrintCount done');
      }else{
        console.log('updateTaskPrintCount not done');
      } 
    },
    refetchQueries :[{query : GET_TASKS}],
    onError: (err) => {
      console.log('err updateTaskPrintCount not done', err);
    },
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      console.log('Print job completed');
      if(type === 'task'){
        updateTaskPrintCount({ variables: { id : data?.id }})
      }
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
          <Stack sx={{ height : '100%', display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
            <Box sx={{ display : 'flex', alignItems : 'flex-start', justifyContent : 'center'}}>
              <Tooltip title={`Imprimer le compte rendu. ${type === 'task' && data?.printCount > 0 ? `Imprimée ${data?.printCount} fois`: ''}`}>
                <IconButton variant="contained" 
                  sx={{backgroundColor: "#e1e1e1", marginRight: 2}} 
                  aria-label="next" onClick={handlePrint} >
                  <Print sx={{ color: type === 'task' && data?.printCount > 0 ? 'pink' : '' }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ width: '100%', display : 'flex', alignItems : 'flex-start', justifyContent : 'center'}}>
              <DocumentToPrint ref={componentRef} data={data} type={type} />
            </Box>
          </Stack>
        </DialogContent>
        <Box sx={{ position : 'fixed', top : 20, right: 20, padding : 2, zIndex: 1000,
                  display : 'flex', alignItems : 'flex-start', justifyContent : 'center'}}>
          <Tooltip title={`Imprimer le compte rendu. ${type === 'task' && data?.printCount > 0 ? `Imprimée ${data?.printCount} fois`: ''}`}>
            <IconButton variant="contained" 
              sx={{backgroundColor: "#e1e1e1", marginRight: 2}} 
              aria-label="next" onClick={handlePrint} >
              <Print sx={{ color: type === 'task' && data?.printCount > 0 ? 'pink' : '' }} />
            </IconButton>
          </Tooltip>
          <IconButton variant="contained" 
            sx={{backgroundColor: "#e1e1e1"}} 
            aria-label="next" onClick={onClose} >
            <Close />
          </IconButton>
        </Box>
    </Dialog>
  );
};



import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import React from 'react';
import SearchDialog from './SearchDialog';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100ch',
      },
    },
  }));

  
const SearchPanel = () => {

    const [isSearchDialogOpen, setIsSearchDialogOpen] = React.useState(false);

    const handleOpenSearchDialog = () => {
        setIsSearchDialogOpen(true);
    };

    const handleCloseSearchDialog = () => {
        setIsSearchDialogOpen(false);
    };

    return (
        <div>
            <Search onClick={handleOpenSearchDialog}>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    onChange={handleOpenSearchDialog}
                    placeholder="Rechercher…"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
            <SearchDialog open={isSearchDialogOpen} onClose={handleCloseSearchDialog} />
        </div>
    );
};

export default SearchPanel;

import * as React from 'react';
import AddClientForm from './AddClientForm';
import AppBar from '@mui/material/AppBar';
import { Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const containerStyle = {
    width: '100%',
    height: '100%'
  };

const Transition = React.forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAddClient({open, onClose}) {
    const handleCloseOk = (client) => {
        onClose(client);
    };
  return (
    <Dialog
        fullScreen
        open={open}
        onClose={() => onClose(null)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Ajouter un client
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose(null)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <AddClientForm onClientAdd={(client)=> handleCloseOk(client)} onCancel={() => onClose(null)}/>
    </Dialog>
  );
}

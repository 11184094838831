import * as React from 'react';
import AddProductServiceForm from './AddProductServiceForm';
import { useParams } from 'react-router-dom';

export default function AddProductService() {
  let { idProductService } = useParams();
  return (
    <AddProductServiceForm idProductService={idProductService} title={(idProductService && idProductService > 0) ? `Modifier le service` : `Ajouter un service`}/>
  );
}

import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Avatar, Stack, Tooltip } from '@mui/material';
import { Delete, PauseRounded, PlayArrowRounded, Edit, Article, Print } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { getFormatDateTime, getRecoveryTypeColor } from '../../../../_shared/tools/functions';
import { OBJECT_RECOVERY_TYPES } from '../../../../_shared/tools/constants';
import { useFeedBacks } from '../../../../_shared/context/feedbacks/FeedBacksProvider';

export default function TheObjectItemCard({theObject, onDeleteTheObject, onUpdateTheObjectState}) {
//   const theme = useTheme();
  const  {  setPrintingModal } = useFeedBacks();
  const onOpenModalToPrint = (task) => {
    setPrintingModal({
        isOpen: true,
        type: 'theObject',
        data: theObject,
        onClose: () => { 
          setPrintingModal({isOpen: false})
          }
      })
  }
  let image = null
  if(theObject?.image && theObject?.image != '') image = theObject?.image
  else if(theObject?.client && theObject?.client?.photo && theObject?.client?.photo != '') image = theObject?.client?.photo
  else if(theObject?.partner && theObject?.partner?.photo && theObject?.partner?.photo != '') image = theObject?.partner?.photo
  return (
    <Card variant="outlined" sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2,  borderColor : getRecoveryTypeColor(theObject?.objectRecovery?.objectRecoveryType), borderLeftWidth: 6 }}>
      <Tooltip title={theObject?.name}>
        <CardMedia component="img" width="100" height="100" alt={theObject?.name}
          src={ image ? image : "/default-placeholder.jpg"}
          sx={{ borderRadius: 0.6, height: 100, width: 100}}
        />
      </Tooltip>
      <Stack direction="column" spacing={2} alignItems="center">
        <Stack direction="column" spacing={0.2} alignItems="center">
          <Typography color="text.primary" fontWeight="medium" fontSize={18}>
            {theObject?.name}
          </Typography>
            <Typography component="div" variant="caption" color="text.secondary" fontWeight="regular" >
              { 
                    `${theObject?.objectRecovery?.objectRecoveryType === OBJECT_RECOVERY_TYPES.RECOVERY ?  
                    `Récupéré le: ${getFormatDateTime(theObject?.objectRecovery?.recoveryDate)}` : 
                    `Retourné le : ${getFormatDateTime(theObject?.objectRecovery?.returnDate)}`} par ${theObject?.objectRecovery?.creator?.firstName}`
                }
            </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Tooltip title="Supprimer">
            <IconButton aria-label="delete" size="small" sx={{ flexGrow: 0 }}
              onClick={()=> onDeleteTheObject(theObject?.id)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={!theObject?.isActive ? 'Activer' : 'Désactiver'}>
            <IconButton
              aria-label={!theObject?.isActive ? 'play' : 'pause'}
              sx={{ mx: 1 }}
              onClick={() => onUpdateTheObjectState(theObject?.id)}
            >
              {!theObject?.isActive ? <PlayArrowRounded /> : <PauseRounded />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier">
            <Link to={`/online/recuperations/objets/modifier/${theObject?.id}`} className="no_style">
              <IconButton aria-label="edit" size="small">
                <Edit fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
          <Stack>
            {
              theObject?.client ? <Tooltip title={theObject?.client?.name}>
                                  <Link to={`/online/ventes/clients/details/${theObject?.client?.id}`} className="no_style">
                                    <Avatar alt={theObject?.client?.name} src={theObject?.client?.photo} />
                                  </Link>
                                </Tooltip>
                                : <Tooltip title={theObject?.partner?.name}>
                                    <Link to={`/online/partenariats/partenaires/details/${theObject?.partner?.id}`} className="no_style">
                                      <Avatar alt={theObject?.partner?.name} src={theObject?.partner?.photo} />
                                    </Link>
                                  </Tooltip>
            }
          </Stack>
          <Tooltip title="Imprimer le compte rendu">
            <IconButton aria-label="print" size="small" sx={{ flexGrow: 0 }}
              onClick={()=> onOpenModalToPrint(theObject)}>
              <Print fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Détails">
            <Link to={`/online/recuperations/objets/details/${theObject?.id}`} className="no_style">
              <IconButton aria-label="edit" size="small">
                <Article fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
}
